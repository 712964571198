import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DateRangeDocumentsFilter } from '../../../../documents/common/constants/constants-documents';
import { ImagingModelSlice } from '../../../../documents/common/models/ReduxSlices';
import DateComponent from '../../../../documents/screens/Filters/DateFilter/DateComponent';
import { RootState } from '../../../redux/store';
interface Props {
    setDate: (param: string) => void;
    date: string;
    dateFilterParam: string;
    setDateFilterParam: (param: string) => void;
    type: string;
    btnClass?: string;
    isOpen: boolean;
    onClick: () => void;
    handleToggle: () => void;
    startDateCa: string;
    endDateCa: string;
    setEndDateCa: (param: string) => void;
    setStartDateCa: (param: string) => void;
    pageWhereComponentIsCalled?: string;
    PropDateValue: (dateValue: string) => void;
    propDateLabel: (dateValue: string) => void;
    setappliedDate: (dateValue: string) => void;
    setappliedType: (dateValue: string) => void;
    secondDropdownFunction?: (
        handleSelection: any,
        dateRangeIndex: any,
        dateRange: any,
        searchTypeCode: any,
        attribute: any,
        handleToggle: any
    ) => void;
    applyButtonFunction: (attribute: string, selectedOption: string, selectedValue: string, endDate: string, startDate: string) => void;
    dateDropdownLabel: string;
    appliedDate: string;
    appliedType: string;
    title: string;
}

const DateRangeFilter = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            btnClass = '',
            onClick,
            isOpen,
            setEndDateCa,
            setStartDateCa,
            handleToggle,
            pageWhereComponentIsCalled,
            date,
            secondDropdownFunction,
            setDate,
            PropDateValue,
            type,
            applyButtonFunction,
            startDateCa,
            endDateCa,
            dateFilterParam,
            setDateFilterParam,
            dateDropdownLabel,
            propDateLabel,
            appliedDate,
            appliedType,
            title
        },
        ref
    ) => {
        const { customDateRangeCaEndLabel, customDateRangeCaStartLabel } = useSelector<RootState, ImagingModelSlice>(
            (state) => state.imaging
        );
        const translate = useTranslation();
        const [showDateRange, setShowDateRange] = useState(false);

        useEffect(() => {
            applyButtonFunction(
                'Date',
                type.replaceAll('_Label', '').replaceAll(' ', ''),
                date.replaceAll('_Label', ''),
                endDateCa,
                startDateCa
            );
        }, [type, date, endDateCa, startDateCa]);

        const renderDropdownLabel = () => {
            if (date) {
                if (!date.includes('-')) return translate(DateRangeDocumentsFilter.find((x) => x.id === date)?.label ?? '');
                else return `${startDateCa.replaceAll('-', '/')} - ${endDateCa.replaceAll('-', '/')}`;
            }
            if (appliedDate) {
                if (!appliedDate.includes('-')) return translate(DateRangeDocumentsFilter.find((x) => x.id === appliedDate)?.label ?? '');
                else return `${startDateCa.replaceAll('-', '/')} - ${endDateCa.replaceAll('-', '/')}`;
            } else return translate('SelectDateByRange_Label');
        };
        const renderDateRangeOptions = () => {
            return (
                <div>
                    {DateRangeDocumentsFilter.map((dateRange, dateRangeIndex) => {
                        if (dateRangeIndex < 8) {
                            return (
                                <div
                                    className={`dropdown-item d-flex justify-content-between align-items-stretch`}
                                    key={dateRange.id}
                                    onClick={() => {
                                        setDate(dateRange.id);
                                        PropDateValue(dateRange.id);
                                        propDateLabel(dateRange.label);
                                        handleToggle();
                                    }}
                                >
                                    <div className='name d-flex align-items-center mr-3'>{translate(dateRange.label)}</div>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    className={`dropdown-item d-flex justify-content-between align-items-stretch`}
                                    key={dateRange.id}
                                    onClick={() => {
                                        setShowDateRange(true);
                                    }}
                                >
                                    <div className='name d-flex align-items-center mr-3'>{translate(dateRange.label)}</div>
                                </div>
                            );
                        }
                    })}
                </div>
            );
        };

        const renderCustomDateRange = () => {
            return (
                <div className='card shadow-none border-none'>
                    <div className='card-header px-4 py-3'>
                        <h5 className='m-0'>{translate('SelectCustomDateRange_Label')}</h5>
                    </div>
                    <div className='card-body px-4 py-3'>
                        <DateComponent
                            placeholder={translate('SelectStartDate_Label')}
                            dateLabel={translate('StartDate_Label')}
                            valMsg='Invalid date message'
                            noBorder
                            noHeader
                            value={startDateCa || customDateRangeCaStartLabel}
                            onDateChanged={setStartDateCa}
                            medium
                            dateCheckObj={{
                                comparationValueMin: new Date(2011, 1, 1),
                                comparationValueMax: new Date(2042, 1, 1)
                            }}
                        />
                        <DateComponent
                            placeholder={translate('SelectEndDate_Label')}
                            dateLabel={translate('EndDate_Label')}
                            valMsg='Invalid date message'
                            noBorder
                            noHeader
                            value={endDateCa || customDateRangeCaEndLabel}
                            onDateChanged={setEndDateCa}
                            medium
                            dateCheckObj={{
                                comparationValueMin: new Date(2011, 1, 1),
                                comparationValueMax: new Date(2042, 1, 1)
                            }}
                        />
                    </div>
                    <div className='card-footer d-flex px-4 py-3'>
                        <button
                            type='button'
                            className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                            onClick={(e) => {
                                setShowDateRange(false);
                                setStartDateCa('');
                                setEndDateCa('');
                            }}
                        >
                            {translate('Cancel_Label')}
                        </button>
                        <button
                            disabled={!endDateCa && !startDateCa}
                            type='button'
                            className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                            onClick={() => {
                                setShowDateRange(false);
                                if (startDateCa !== '' && endDateCa !== '') setDate(`${startDateCa}-${endDateCa}`);
                                PropDateValue(`${startDateCa}-${endDateCa}`);
                                handleToggle();
                            }}
                        >
                            {translate('Apply_Label')}
                        </button>
                    </div>
                </div>
            );
        };
        return (
            <div className={`dropdown ${isOpen ? 'show' : ''}`} ref={!showDateRange ? ref : null}>
                {/* Dropdown header */}
                <button
                    className={`btn btn-tertiary ${btnClass} dropdown-toggle btn-sm`}
                    type='button'
                    onClick={onClick}
                    disabled={!type && !appliedType}
                >
                    {renderDropdownLabel()}
                </button>

                {/* Dropdown menu */}
                <div id='DateRangePicker' className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby='Filter'>
                    {isOpen && !showDateRange && renderDateRangeOptions()}
                    {showDateRange && renderCustomDateRange()}
                </div>
            </div>
        );
    }
);

export default DateRangeFilter;
