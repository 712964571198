import React from 'react';
import CompleteEntryDataPage from './screens/completeEntryData/CompleteEntryDataPage';

const CompleteEntryData = () => {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <CompleteEntryDataPage />
            </div>
        </main>
    );
};

export default React.memo(CompleteEntryData);
