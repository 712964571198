import { useEffect, useMemo, useState } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import FixedFooterWhite from '../../common/features/FixedFooterWhite';
import { isMLPFeatureSelected } from '../../common/functions/utils';
import type { TeamMemberData } from '../../common/models/ResponseData';
import { useTeamSelector } from '../../redux/hooks';

interface EditTeamMemberFooterProps {
    onSaveUser: (payload: { data: TeamMemberData; editData: TeamMemberData }) => void;
}

const EditTeamMemberFooter: React.FC<EditTeamMemberFooterProps> = ({ onSaveUser }) => {
    const { user } = useTeamSelector((state) => state.hydration);
    const {
        data: userDetailsData,
        editData,
        userDetailsIsValid,
        isLoading: userDetailsLoading,
        error: userDetailsError
    } = useTeamSelector((state) => state.editTeamMember);
    const {
        data: clientsAndPermissionsData,
        isPending,
        isLoading: clientsAndPermissionsLoading,
        error: clientsAndPermissionsError
    } = useTeamSelector((state) => state.clientsAndPermissions);

    const translate = useTranslation();

    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const mlpFeatureSelectedIsValid = useMemo(
        () => isMLPFeatureSelected(clientsAndPermissionsData?.ParentServices),
        [clientsAndPermissionsData?.ParentServices]
    );
    const [message, setMessage] = useState('');
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);

    const teamMember = userDetailsData?.Users?.[0];
    const isPendingStatus =
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';
    const isSaveDisabled = isPendingStatus || isInactiveStatus;

    useEffect(() => {
        if (!formIsSubmitted) {
            return;
        }
        if (!mlpFeatureSelectedIsValid) {
            setMessage(
                userDetailsData?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label')
            );
        } else if (!userDetailsIsValid) {
            setMessage(translate('InvalidUserDetails_Label'));
        } else {
            setMessage('');
        }
    }, [formIsSubmitted, mlpFeatureSelectedIsValid, translate, userDetailsData?.AdminRole, userDetailsIsValid]);

    useEffect(() => {
        if (isPendingStatus) setMessage(translate('PendingFeatureSetupLocked_Label'));
        else if (isInactiveStatus) {
            setMessage(translate('InactiveTeamMemberLocked_Label'));
        } else {
            setMessage('');
        }
    }, [isPendingStatus, isInactiveStatus, translate]);

    const handleSavingUsers = () => {
        setFormIsSubmitted(true);
        if (userDetailsData && editData && mlpFeatureSelectedIsValid && userDetailsIsValid) {
            onSaveUser({
                data: { ...userDetailsData, ...clientsAndPermissionsData },
                editData: { ...editData, ...clientsAndPermissionsData }
            });
        }
    };

    return (
        <>
            {isEditable &&
                !isPending &&
                !userDetailsLoading &&
                !userDetailsError &&
                !clientsAndPermissionsLoading &&
                !clientsAndPermissionsError && (
                    <FixedFooterWhite
                        primaryLabel={translate('TeamMemberSaveChanges_Label')}
                        primaryOnClick={handleSavingUsers}
                        primaryDisabled={isSaveDisabled}
                        message={message}
                    />
                )}
        </>
    );
};

export default EditTeamMemberFooter;
