import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../../common/features/Button';
import FormDatePicker from '../../../../common/features/FormDatePicker';
import FormInputField from '../../../../common/features/FormInputField';
import Select from '../../../../common/features/Select';
import { dateFormatValidator, dateIsAfterValidator, emailValidator, inputHasValueValidator } from '../../../../common/functions/validators';
import useInputValidation from '../../../../common/hooks/useInputValidation';
import useMaskedInputValidation from '../../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../common/hooks/useTranslation';
import { validateNewTeamMember } from '../../api/utils';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { addTeamMember, addUser, removeUser, resetUserDetailsData } from '../../redux/reducers/userDetailsSlice';
import AddNewUserTable from './AddNewUserTable';
import TeamSectionWrapper from './TeamSectionWrapper';

const UserDetails = () => {
    const { newUsers: newUsersUserDetailsSlice, data, isLoading } = useTeamSelector((state) => state.userDetails);
    const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
    const [emailExists, setEmailExists] = useState<boolean>(false);
    const [emailValidationInProgress, setEmailValidationInProgress] = useState<boolean>(false);
    const dispatch = useTeamDispatch();
    const translate = useTranslation();
    const userRoles = useMemo(() => {
        if (data?.Roles) {
            const dataRoles = data?.Roles?.map((item) => {
                return { value: item.Text ? item.Text : '', label: '' };
            });
            return [{ value: '', label: translate('SelectUserRole_Label') }, ...dataRoles];
        } else {
            return [{ value: '', label: translate('SelectUserRole_Label') }];
        }
    }, [data?.Roles, translate]);

    const {
        value: firstName,
        handleChange: firstNameChangeHandler,
        handleBlur: firstNameBlurHandler,
        handleReset: firstNameResetHandler,
        isValid: firstNameIsValid,
        hasError: firstNameHasError
    } = useInputValidation({ validators: [inputHasValueValidator], validateOnSubmit: formIsSubmitted });
    const {
        value: lastName,
        handleChange: lastNameChangeHandler,
        handleBlur: lastNameBlurHandler,
        handleReset: lastNameResetHandler,
        isValid: lastNameIsValid,
        hasError: lastNameHasError
    } = useInputValidation({ validators: [inputHasValueValidator], validateOnSubmit: formIsSubmitted });
    const {
        value: email,
        handleChange: emailChangeHandler,
        handleBlur: emailBlurHandler,
        handleReset: emailResetHandler,
        isValid: emailIsValid,
        hasError: emailHasError,
        errorIndex: emailErrorIndex
    } = useInputValidation({
        validators: [inputHasValueValidator, emailValidator, () => !emailExists],
        validateOnSubmit: formIsSubmitted
    });
    const {
        value: company,
        handleChange: companyChangeHandler,
        handleBlur: companyBlurHandler,
        handleReset: companyResetHandler,
        isValid: companyIsValid,
        hasError: companyHasError
    } = useInputValidation({ validators: [inputHasValueValidator], validateOnSubmit: formIsSubmitted });
    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        handleReset: phoneNumberResetHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        validators: [inputHasValueValidator, (value) => isValidPhoneNumber(value, 'US')],
        validateOnSubmit: formIsSubmitted,
        inputMask: '(###) ###-#### x######'
    });
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        handleReset: mobileNumberResetHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        validators: [(value) => isValidPhoneNumber(value, 'US')],
        required: false,
        inputMask: '(###) ###-####'
    });
    const {
        value: date,
        handleChange: dateChangeHandler,
        handleBlur: dateBlurHandler,
        handleReset: dateResetHandler,
        handlePicker: datePickerHandler,
        hasError: dateHasError,
        isValid: dateIsValid
    } = useInputValidation({
        validators: [dateFormatValidator, dateIsAfterValidator],
        required: false
    });
    const {
        value: selectedRole,
        handleReset: roleResetHandler,
        handlePicker: roleChangeHandler,
        hasError: roleHasError,
        isValid: roleIsValid
    } = useInputValidation({
        validators: [inputHasValueValidator],
        validateOnSubmit: formIsSubmitted
    });

    const handleRemoveUser = useCallback(
        (index: number) => {
            dispatch(removeUser(index));
        },
        [dispatch]
    );

    // Reset "data" on unmount from the "userDetails" slice
    useEffect(() => {
        return () => {
            dispatch(resetUserDetailsData());
        };
    }, [dispatch]);

    const handleAddUser = (options: {
        firstName: string;
        lastName: string;
        email: string;
        companyName: string;
        phone: string;
        mobileNumber: string;
        userRole: string;
        expirationDate: string;
    }) => {
        const roles = data?.Roles?.map((item) => {
            if (item?.Text === options.userRole) {
                return { ...item, IsSelected: true };
            } else {
                return { ...item, IsSelected: false };
            }
        });
        dispatch(
            addUser({
                FirstName: options.firstName.trim(),
                LastName: options.lastName.trim(),
                Email: options.email.trim(),
                CompanyName: options.companyName.trim(),
                Phone: options.phone.trim(),
                MobileNumber: options.mobileNumber.trim(),
                UserRole: options.userRole,
                Roles: roles,
                ExpirationDate: options.expirationDate.trim()
            })
        );

        const newUser = {
            FirstName: options.firstName.trim(),
            LastName: options.lastName.trim(),
            Email: options.email.trim(),
            CompanyName: options.companyName.trim(),
            Phone: options.phone.trim(),
            MobileNumber: options.mobileNumber.trim(),
            UserRole: options.userRole,
            Roles: roles,
            ExpirationDate: options.expirationDate.trim()
        };
        const newUsers = [...newUsersUserDetailsSlice, newUser];
        const changedUsers = [...newUsersUserDetailsSlice, newUser]
            .map((item, index) =>
                Object.entries(item).map((item) => {
                    if (item[1] && item[0] !== 'Roles' && item[0] !== 'UserRole') {
                        return `Users.${index}.${item[0]}`;
                    } else {
                        return undefined;
                    }
                })
            )
            .flat()
            .filter((item) => item);

        dispatch(addTeamMember({ changedUsers, users: newUsers }));
    };

    const handleSubmit = () => {
        setFormIsSubmitted(true);
        // Handle required and optional parameters, "isValid" for required, "hasError" for optional
        if (
            firstNameIsValid &&
            lastNameIsValid &&
            emailIsValid &&
            companyIsValid &&
            phoneNumberIsValid &&
            roleIsValid &&
            !mobileNumberHasError &&
            !dateHasError
        ) {
            firstNameResetHandler();
            lastNameResetHandler();
            emailResetHandler();
            companyResetHandler();
            phoneNumberResetHandler();
            mobileNumberResetHandler();
            dateResetHandler();
            roleResetHandler();
            handleAddUser({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email: email.trim(),
                companyName: company.trim(),
                phone: phoneNumber.trim(),
                mobileNumber: mobileNumber.trim(),
                userRole: selectedRole,
                expirationDate: date.trim()
            });

            setFormIsSubmitted(false);
        }
    };

    return (
        <TeamSectionWrapper title={translate('AddUsersAddUserDetails_Label')} isLoading={isLoading}>
            <div className='container-fluid p-0'>
                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                    <div className='col order-md-1'>
                        <div className='form-group'>
                            <FormInputField
                                label={translate('FirstName_Label')}
                                value={firstName}
                                onChange={firstNameChangeHandler}
                                onBlur={firstNameBlurHandler}
                                isValid={firstNameIsValid}
                                hasError={firstNameHasError}
                            />
                            {firstNameHasError && <div className='error-message'>{translate('RequiredFirstName_Label')}</div>}
                        </div>
                    </div>
                    <div className='col order-md-2'>
                        <div className='form-group'>
                            <FormInputField
                                label={translate('LastName_Label')}
                                value={lastName}
                                onChange={lastNameChangeHandler}
                                onBlur={lastNameBlurHandler}
                                isValid={lastNameIsValid}
                                hasError={lastNameHasError}
                            />
                            {lastNameHasError && <div className='error-message'>{translate('RequiredLastName_Label')}</div>}
                        </div>
                    </div>
                    <div className='col order-md-4'>
                        <div className='form-group'>
                            <FormInputField
                                label={translate('Email_Label')}
                                value={email}
                                onChange={(e) => {
                                    emailChangeHandler(e);
                                    setEmailExists(false);
                                }}
                                onBlur={(e) => {
                                    emailBlurHandler(e);
                                    if (emailIsValid) {
                                        setEmailValidationInProgress(true);
                                        if (newUsersUserDetailsSlice.some((item) => item.Email === email.trim())) {
                                            setEmailValidationInProgress(false);
                                            setEmailExists(true);
                                            return;
                                        }
                                        validateNewTeamMember({ Email: email.trim() }).then((res) => {
                                            setEmailValidationInProgress(false);
                                            if (res?.emailExists) {
                                                setEmailExists(true);
                                            }
                                        });
                                    }
                                }}
                                isValid={emailIsValid}
                                hasError={emailHasError}
                                validationInProgress={emailValidationInProgress}
                                autoComplete='email'
                            />
                            {emailHasError && emailErrorIndex === 0 && (
                                <div className='error-message'>{translate('RequiredEmail_Label')}</div>
                            )}
                            {emailHasError && emailErrorIndex === 1 && (
                                <div className='error-message'>{translate('InvalidEmail_Label')}</div>
                            )}
                            {emailHasError && emailErrorIndex === 2 && (
                                <div className='error-message'>{translate('AlreadyRegisteredEmail_Label')}</div>
                            )}
                        </div>
                    </div>

                    <div className='col order-md-5'>
                        <div className='form-group'>
                            <FormInputField
                                label={translate('PhoneNumber_Label')}
                                value={phoneNumber}
                                onChange={phoneNumberChangeHandler}
                                onBlur={phoneNumberBlurHandler}
                                isValid={phoneNumberIsValid}
                                hasError={phoneNumberHasError}
                            />
                            {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                                <div className='error-message'>{translate('RequiredPhoneNumber_Label')}</div>
                            )}
                            {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                                <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                            )}
                        </div>
                    </div>

                    <div className='col order-md-6'>
                        <div className='form-group'>
                            <FormInputField
                                label={translate('MobileNumber_Label')}
                                value={mobileNumber}
                                onChange={mobileNumberChangeHandler}
                                onBlur={mobileNumberBlurHandler}
                                isValid={mobileNumberIsValid}
                                hasError={mobileNumberHasError}
                            />
                            {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                        </div>
                    </div>

                    <div className='col order-md-7'>
                        <div className='form-group'>
                            <FormInputField
                                label={translate('Company_Label')}
                                value={company}
                                onChange={companyChangeHandler}
                                onBlur={companyBlurHandler}
                                isValid={companyIsValid}
                                hasError={companyHasError}
                            />
                            {companyHasError && <div className='error-message'>{translate('RequiredCompany_Label')}</div>}
                        </div>
                    </div>

                    <div className='col order-md-8'>
                        <div className='form-group'>
                            <Select
                                label={translate('UserRole_Label')}
                                options={userRoles}
                                onChange={roleChangeHandler}
                                value={selectedRole}
                                isValid={roleIsValid}
                                hasError={roleHasError}
                            />
                            {roleHasError && <div className='error-message'>{translate('RequiredRole_Label')}</div>}
                        </div>
                    </div>

                    <div className='col order-md-9'>
                        <div className='form-group'>
                            <FormDatePicker
                                label={translate('ExpirationDate_Label')}
                                noBorder
                                noHeader
                                value={date}
                                onChange={dateChangeHandler}
                                onDateChange={datePickerHandler}
                                onBlur={dateBlurHandler}
                                dateFormat='MM-DD-YYYY'
                                hasError={dateHasError}
                                isValid={dateIsValid}
                                placeholder={translate('NoExpirationDate_Label')}
                            />
                            {dateHasError && <div className='error-message'>{translate('InvalidDate_Label')}</div>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mt-2'>
                        <Button variant='tertiary-blue' onClick={handleSubmit} disabled={emailValidationInProgress}>
                            <FontAwesomeIcon icon={faUserPlus} className={'mr-1'} />
                            {translate('CreateUser_Label')}
                        </Button>
                    </div>
                </div>
                <AddNewUserTable data={newUsersUserDetailsSlice} onRemoveUser={handleRemoveUser} />
            </div>
        </TeamSectionWrapper>
    );
};

export default UserDetails;
