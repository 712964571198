import { SvgIcon } from '@progress/kendo-react-common';
import { GridColumnMenuProps, GridColumnMenuSort } from '@progress/kendo-react-grid';
import { filterIcon, searchIcon, xIcon } from '@progress/kendo-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Document } from '../../../../../common/models/ClearanceDocuments';
import { ClientSelectionSlice } from '../../../../../common/models/ReduxSlices';
import { setDateDropdownLabel } from '../../../redux/reducers/dateFilterSlice';
import { setCheckedDocuments } from '../../../redux/reducers/docTypeSlice';
import { RootState } from '../../../redux/store';
import { generateGridKey } from '../../../utils/imaging-utils';
import { DocumentsTypeFilterCA, DocumentsTypeFilterUS } from '../../constants/constants-documents';
import { DateFilterSlice, DocTypeSlice } from '../../models/ReduxSlices';

export interface CustomsGridColumnMenuProps extends GridColumnMenuProps {
    valueStartDate: Date | null;
    valueEndDate: Date | null;
    setValueStartDate: (param: Date | null) => void;
    setValueEndtDate: (param: Date | null) => void;
}
export const ColumnMenuDateFilter: React.FC<CustomsGridColumnMenuProps> = React.memo(
    ({ valueEndDate, valueStartDate, setValueStartDate, setValueEndtDate, ...props }) => {
        const { dateDropdownLabel } = useSelector<RootState, DateFilterSlice>((state) => state.dateFilter);
        const translate = useTranslation();
        const dispatch = useDispatch();

        const dateRangeOptions = [
            'DateRangeTwoDays_Label',
            'DateRangeSevenDays_Label',
            'DateRangeThirtyDays_Label',
            'DateRangeSixtyDays_Label',
            'DateRangeNinetyDays_Label',
            'DateRangeThisYear_Label',
            'DateRangeLastYear_Label',
            'DateRangeAllTime_Label'
        ];

        return (
            <>
                <GridColumnMenuSort {...props} />
                <div style={{ cursor: 'pointer' }} className='dropdown'>
                    <span
                        className='dropdown-toggle font-weight-bold'
                        id='dropdownTextToggle'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                    >
                        {translate(dateDropdownLabel)}
                    </span>
                    <div className='dropdown-menu' aria-labelledby='dropdownTextToggle'>
                        {dateRangeOptions.map((item, index) => (
                            <div
                                key={index}
                                className={`dropdown-item d-flex justify-content-between align-items-stretch cursor-pointer ${
                                    dateDropdownLabel === item ? 'active' : ''
                                }`}
                                onClick={() => dispatch(setDateDropdownLabel(item))}
                            >
                                <div className='mr-3'>{translate(item)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
);

export interface CustomGridColumnMenuProps extends GridColumnMenuProps {
    documents: Array<{ selected: boolean } & Document>;
    filter: any;
    setFilter: any;
    gridKey: number;
    setGridKey: React.Dispatch<React.SetStateAction<number>>;
}

export const ColumnMenuCheckboxFilter: React.FC<CustomGridColumnMenuProps> = ({
    documents,
    filter,
    setFilter,
    onCloseMenu,
    gridKey,
    setGridKey,
    ...props
}) => {
    const [checkAll, setCheckAll] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const translate = useTranslation();
    const dispatch = useDispatch();
    const { checkedDocuments } = useSelector<RootState, DocTypeSlice>((state) => state.docFilter);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const filteredDocuments = documents.filter((document) => document.docType.toLowerCase().includes(searchText.toLowerCase()));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchText('');
    };

    const handleFilterClick = () => {
        setFilter(checkedDocuments);
        setGridKey(generateGridKey(gridKey));
    };

    const handleClearClick = () => {
        setFilter({});
        dispatch(setCheckedDocuments({}));
    };
    useEffect(() => {
        const filterTrueProperties = (obj: Record<string, boolean>) => {
            return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value === true));
        };
        if (Object.keys(filterTrueProperties(checkedDocuments)).length === documents.length) setCheckAll(true);
    }, []);
    useEffect(() => {
        const allFilteredChecked = filteredDocuments.every((document) => checkedDocuments[document.documentId]);
        setCheckAll(allFilteredChecked && filteredDocuments.length > 0);
    }, [checkedDocuments, filteredDocuments]);

    const handleSingleCheck = (documentId: string) => {
        const newCheckedDocuments = {
            ...checkedDocuments,
            [documentId]: !checkedDocuments[documentId]
        };
        dispatch(setCheckedDocuments(newCheckedDocuments));
    };

    const checkAllHandler = () => {
        const newCheckedState = !checkAll;
        const newCheckedDocuments = {
            ...checkedDocuments,
            ...filteredDocuments.reduce<Record<string, boolean>>((acc, document) => {
                acc[document.documentId] = newCheckedState;
                return acc;
            }, {})
        };

        dispatch(setCheckedDocuments(newCheckedDocuments));
        setCheckAll(newCheckedState);
    };

    const checkDisable = () => {
        if (Object.keys(checkedDocuments).length === 0) return true;
        for (const property in checkedDocuments) {
            if (checkedDocuments[property]) return false;
        }
        return true;
    };

    const docTypeFiltersHashMap = country?.Code === 'us' ? [...DocumentsTypeFilterUS] : [...DocumentsTypeFilterCA];

    return (
        <div style={{ marginTop: '10px', width: '100%', maxWidth: '300px', boxSizing: 'border-box' }}>
            <GridColumnMenuSort {...props} />
            <div style={{ marginTop: '10px', width: '100%', maxWidth: '270px', boxSizing: 'border-box' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SvgIcon icon={filterIcon} style={{ width: '16px', height: '16px', marginRight: '5px' }} />
                    <span style={{ fontSize: '14px' }}>{translate('Filter_Label')}</span>
                </div>
                <div style={{ position: 'relative', marginTop: '5px', width: '100%', maxWidth: '300px', boxSizing: 'border-box' }}>
                    <SvgIcon
                        icon={searchIcon}
                        style={{
                            width: '16px',
                            height: '16px',
                            position: 'absolute',
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none'
                        }}
                    />
                    <input
                        type='text'
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder={translate('Search_Label')}
                        style={{
                            padding: '5px 30px 5px 30px',
                            fontSize: '14px',
                            width: '100%',
                            boxSizing: 'border-box'
                        }}
                    />
                    <SvgIcon
                        icon={xIcon}
                        onClick={handleClearSearch}
                        style={{
                            width: '16px',
                            height: '16px',
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                <div
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginTop: '10px',
                        width: '100%',
                        maxWidth: '300px',
                        boxSizing: 'border-box'
                    }}
                >
                    <div style={{ marginBottom: '3px', display: 'flex', alignItems: 'center' }}>
                        <input
                            type='checkbox'
                            name={'selectAll'}
                            id={'selectAll'}
                            checked={checkAll}
                            onChange={checkAllHandler}
                            style={{
                                marginRight: '5px',
                                marginLeft: '10px',
                                width: '20px',
                                height: '20px',
                                borderRadius: '80%',
                                border: '2px solid #000',
                                accentColor: '#0070e0'
                            }}
                        />
                        <label htmlFor={'selectAll'} style={{ marginLeft: '2px' }}>
                            {translate('SelectAll_Label')}
                        </label>
                    </div>
                    {documents
                        .filter((document) => document.docType.toLowerCase().includes(searchText.toLowerCase()))
                        .map((document) => (
                            <div key={document.documentId} style={{ marginBottom: '3px', display: 'flex', alignItems: 'center' }}>
                                <input
                                    type='checkbox'
                                    name={document.docType}
                                    id={document.documentId}
                                    checked={checkedDocuments[document.documentId]}
                                    onChange={() => handleSingleCheck(document.documentId)}
                                    style={{
                                        marginRight: '5px',
                                        marginLeft: '10px',
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '80%',
                                        border: '2px solid #000',
                                        accentColor: '#0070e0'
                                    }}
                                />
                                <label htmlFor={document.documentId} style={{ marginLeft: '2px' }}>
                                    {`${
                                        translate(
                                            docTypeFiltersHashMap.find((type) => type.id === document.metadataFormat?.trim().toUpperCase())
                                                ?.label
                                        ) || ''
                                    }${
                                        document.secondaryDescription?.replace('-', '')?.toUpperCase() ===
                                            document.metadataFormat?.toUpperCase() || !document.secondaryDescription?.replace('-', '')
                                            ? ''
                                            : ' - ' + (document.secondaryDescription?.replace('-', '') || '')
                                    }${country?.Code === 'us' && document.USCICONSOL ? ' - ' + document.USCICONSOL : ''}`}
                                </label>
                            </div>
                        ))}
                </div>
                <div style={{ padding: '10px', marginTop: '10px', display: 'flex', gap: '10px' }}>
                    <button
                        disabled={checkDisable()}
                        className='btn btn-primary btn-lg'
                        onClick={handleFilterClick}
                        style={{ padding: '5px 10px', fontSize: '14px', flex: '1' }}
                    >
                        {translate('Filter_Label')}
                    </button>
                    <button
                        className='btn btn-primary btn-lg'
                        onClick={handleClearClick}
                        style={{ padding: '5px 10px', fontSize: '14px', flex: '1' }}
                    >
                        {translate('Clear_Label')}
                    </button>
                </div>
            </div>
        </div>
    );
};
