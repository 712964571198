import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PortalRoutes } from '../../common/constants/constants-portal';
import ShipmentDetails from './screens/shipmentdetails/ShipmentDetails';
import ShipmentList from './screens/shipmentlist/ShipmentList';

const Clearances = () => {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path={PortalRoutes.clearances} exact component={ShipmentList} />
                    <Route path={`${PortalRoutes.clearances}/:customsInfoID`} exact component={ShipmentDetails} />
                </Switch>
            </div>
        </main>
    );
};

export default React.memo(Clearances);
