import type { ParentServices } from '../models/TeamMember';

// Check if MLP ("My Livingston Portal") feature is selected
export const isMLPFeatureSelected = (arr: null | undefined | ParentServices[]) => {
    if (!arr) return false;

    for (const parentService of arr) {
        if (parentService.ClientServices) {
            for (const clientService of parentService.ClientServices) {
                if (clientService.Features) {
                    for (const feature of clientService.Features) {
                        if (feature.Name?.toLowerCase() === 'my livingston portal' && feature.IsSelected === true) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
};
