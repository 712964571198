import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Breakpoints } from '../../../common/constants/constants-portal';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { resetAdvancedSearchSettings } from '../../../pages/clearances/redux/reducers/advancedSearchSettingsSlice';
import { resetDateRange } from '../../../pages/clearances/redux/reducers/dateRangeSlice';
import { resetSearchFilter } from '../../../pages/clearances/redux/reducers/searchSlice';
import { resetShipmentGridSettings } from '../../../pages/clearances/redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../pages/clearances/redux/reducers/shipmentStatusSlice';
import { LivingstonLogoPortal } from '../../features/LivingstonLogoPortal';
import useMediaQuery from '../../hooks/useMediaQuery';
import SidebarCloseButton from './SidebarCloseButton';
import SidebarNavFooter from './SidebarNavFooter';
import SidebarNavItem from './SidebarNavItem';

interface SidebarProps {
    isSidebarOpen: boolean;
    navigationItems: {
        id: string;
        label: string;
        url: string;
        icon: IconDefinition;
    }[];
    onToggleSidebar: () => void;
    setSidebarStatus: (value: boolean) => void;
    onLanguageChange: (payload: number) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, navigationItems, onToggleSidebar, setSidebarStatus, onLanguageChange }) => {
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(Breakpoints.Mobile);
    const translate = useTranslationHydration();

    // Initially only show the Sidebar on Desktop
    useEffect(() => {
        setSidebarStatus(isDesktop ? true : false);
    }, [setSidebarStatus, isDesktop]);

    // Reset all filters to defaults
    const resetAllFilters = () => {
        dispatch(resetDateRange());
        dispatch(resetStatus());
        dispatch(resetSearchFilter());
        dispatch(resetAdvancedSearchSettings());
        dispatch(resetShipmentGridSettings());
    };

    return (
        <>
            <nav className={`sidebar d-flex flex-column justify-content-between ${isSidebarOpen ? 'showing' : ''}`}>
                <div className='sidebar-menu'>
                    {/* Header */}
                    <div className='sidebar-header'>
                        <div className='brand-color-strip lii-portal-accent'></div>
                        <div className='brand-logo d-flex justify-content-between align-items-center'>
                            <LivingstonLogoPortal />
                            <SidebarCloseButton icon={faTimes} onClick={onToggleSidebar} />
                        </div>
                    </div>
                    {/* Navigation */}
                    <div className='list-group sidebar-list-group'>
                        {navigationItems.map((item) => {
                            return (
                                <SidebarNavItem
                                    key={item.id}
                                    title={translate(item.label)}
                                    url={item.url}
                                    icon={item.icon}
                                    onClick={() => {
                                        resetAllFilters();
                                    }}
                                />
                            );
                        })}
                        {/* {user?.HasAccessToPayments === true && !user.InternalUser && (
                            <SidebarNavExternalItem title={translate('Payments_Label')} url={user.PaymentsUrl} icon={faCreditCard} />
                        )}
                        {user?.Roles && user.Roles.includes('Dashboard_Access') && (
                            <SidebarNavExternalItem title='My Livingston' url={'/Dashboard'} icon={faPaperPlane} />
                        )} */}
                    </div>
                </div>
                {/* Footer */}
                <SidebarNavFooter onLanguageChange={onLanguageChange} />
            </nav>
            {isSidebarOpen && !isDesktop && <div className='overlay' onClick={onToggleSidebar}></div>}
        </>
    );
};

export default React.memo(Sidebar);
